// Copyright 2024, Imprivata, Inc.  All rights reserved.

import React from 'react';
import ReactDOM from 'react-dom';
import './tracing/initialize-tracing';

import { App } from './App';
import { LocaleProvider } from './i18n/localeProvider';
import './index.less';

ReactDOM.render(
  <React.StrictMode>
    <LocaleProvider>
      <App />
    </LocaleProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
