// Copyright 2025, Imprivata, Inc.  All rights reserved.

import { Resource } from '@opentelemetry/resources';
import { ATTR_SERVICE_NAME } from '@opentelemetry/semantic-conventions';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { ZipkinExporter } from '@opentelemetry/exporter-zipkin';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import getConfig from '../appConfigUtils';

const { ZIPKIN_TRACING_URL, OTLP_TRACING_URL } = getConfig();

const spanProcessors = [];

if (ZIPKIN_TRACING_URL) {
  spanProcessors.push(
    new BatchSpanProcessor(new ZipkinExporter({ url: ZIPKIN_TRACING_URL })),
  );
}

if (OTLP_TRACING_URL) {
  spanProcessors.push(
    new BatchSpanProcessor(new OTLPTraceExporter({ url: OTLP_TRACING_URL })),
  );
}

const provider = new WebTracerProvider({
  resource: Resource.default().merge(
    new Resource({
      [ATTR_SERVICE_NAME]: 'idp-web-ui',
    }),
  ),
  spanProcessors,
});

provider.register({
  contextManager: new ZoneContextManager(),
});

registerInstrumentations({
  instrumentations: [
    new FetchInstrumentation({
      measureRequestSize: true,
    }),
    new XMLHttpRequestInstrumentation({
      measureRequestSize: true,
    }),
  ],
});
